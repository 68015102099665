/* eslint-disable */

function updateItem(item: any, selected: number, yesColor: string, notColor: string) {
    item.selected = selected;
    item.yesColor = yesColor;
    item.notColor = notColor;
}

export default {

    onClickNull(item: any) {
        updateItem(item, 0, "gainsboro", "gainsboro");
    },

    onClickYes(item: any) {
        if (item.selected == 1) {
            updateItem(item, 0, "gainsboro", "gainsboro");
        } else {
            updateItem(item, 1, "success", "gainsboro");
        }
    },

    onClickNot(item: any) {
        if (item.selected == 2) {
            updateItem(item, 0, "gainsboro", "gainsboro");
        } else {
            updateItem(item, 2, "gainsboro", "danger");
        }
    },

    //定时关闭刷新动画
    closeRefreshAnimation(RefreshAnimationEvent: any, num: number) {
        num = num || 2000;
        setTimeout(() => {
            if (RefreshAnimationEvent) {
                RefreshAnimationEvent.target.complete();
            }
        }, num);
    },



}

