import { Storage } from '@ionic/storage';

// 创建一个存储实例
const storage = new Storage();

// 创建一个异步函数来初始化存储
async function initStorage() {
    await storage.create();
    return storage;
}

// 导出已初始化的存储实例
const ionicStorage = initStorage();
export default ionicStorage;
