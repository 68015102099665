/* eslint-disable */

import {createApp} from 'vue'
import App from './App.vue'
import router from './router';
import {IonicVue, alertController, modalController} from '@ionic/vue';
import moment from 'moment';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* 自定义js文件 */
import store from "./js/store"
import ajax from './js/http';
import tools from './js/tools';
import update from './js/update';
import myConfig from './js/config';
import myWS from './js/ws';
import Const from './js/Const';
import {BRAND_IMAGES, BRAND_BACKGROUND_COLOR} from "@/js/brandImages";
import myCommon from "@/js/common";
import myAjax from "axios";

function switchShortCutIcon() {
    const brandImages = BRAND_IMAGES
    const dom = document.getElementById('shortcut') as any
    dom.href = brandImages.favicon
}

switchShortCutIcon()

// 动态获取返回键图片
function dynamicGetBackButtonPic() {
    document.documentElement.style.setProperty('--back-ion-button', `url(${BRAND_IMAGES.back}) no-repeat center center`)
    document.documentElement.style.setProperty('--brand_background_color', `${BRAND_BACKGROUND_COLOR}`)
}

dynamicGetBackButtonPic()


const app = createApp(App, {
    "version": "1.0",
    "configurations": [
        {
            "type": "android-webview",
            "request": "attach",
            "name": "eduApp",
            "webRoot": "${workspaceFolder}/www",
            "sourceMaps": true,
            "sourceMapPathOverrides": {
                "webpack:/*": "${workspaceFolder}/*"
            }
        }
    ]
}).use(IonicVue, {
    rippleEffect: false,
    mode: 'md'
}).use(router);

//全局属性
const global = app.config.globalProperties;

global.store = store;
global.ajax = ajax;
global.tools = tools;
global.moment = moment;
global.update = update;
global.myWS = myWS;//工具类
global.Const = Const;//工具类
global.myConfig = myConfig.config;       //品牌
global.myCommon = myCommon;

global.version = myConfig.version;          //版本
global.brand = myConfig.config.brand;       //品牌
global.hostname = myConfig.config.hostname; //域名

// global.voice_path = "https://ydl-static.oss-cn-hangzhou.aliyuncs.com/voice/";
global.voice_path = store.getOEMBASE() + "voice/";

global.userinfo = {
    username: undefined
}
global.BRAND_BACKGROUND_COLOR = BRAND_BACKGROUND_COLOR
//当前登录用户ID
global.currentUserId = undefined;
global.setCurrentUserId = function (currentUserId: any) {
    global.currentUserId = currentUserId;
};

//正在上课的学员ID
global.currentStudentId = undefined;
global.setCurrentStudentId = function (currentStudentId: any) {
    global.currentStudentId = currentStudentId;
};

//正在上课的资料ID
global.currentCourseId = undefined;
global.setCurrentCourseId = function (currentCourseId: any) {
    global.currentCourseId = currentCourseId;
};

//正在上课的预约ID
global.currentAppointmentId = undefined;
global.setCurrentAppointmentId = function (currentAppointmentId: any) {
    global.currentAppointmentId = currentAppointmentId;
};

//学后检测页面是否处于打开状态
global.pageAfterTestIsOpen = false;
global.setPageAfterTestIsOpen = function (val: any) {
    global.pageAfterTestIsOpen = val;
};

global.back = function () {
    history.back();
};

//直接关闭
global.onClose = function (params: any) {
    modalController.dismiss(params);
};

//带确认关闭
global.onConfirmClose = async function () {
    const alert = await alertController.create({
        header: "提示",
        message: "确认回退吗？",
        backdropDismiss: false,//点击背景不关闭
        buttons: [
            {
                text: '取消',
            },
            {
                text: '确定',
                handler: () => {
                    modalController.dismiss();
                },
            }
        ],
    });
    await alert.present();
};

global.alert = async function (message: string, method: any, title: string) {
    return new Promise<void>(async (resolve) => {
        if (title == undefined) {
            title = "提示信息";
        }

        const alert = await alertController.create({
            header: title,
            message: message,
            backdropDismiss: false,//点击背景不关闭
            buttons: [{
                text: '确定',
                handler: () => {
                    if (method) {
                        method();
                    }
                    resolve()
                },
            }],
        });
        await alert.present();
    })
}

global.myConfirm = async function (message: string, method: any, title: string) {
    return new Promise<void>(async (resolve) => {
        if (title == undefined) {
            title = "提示信息";
        }

        const alert = await alertController.create({
            header: title,
            message: message,
            backdropDismiss: false,//点击背景不关闭
            buttons: [
                {
                    text: '取消',
                },
                {
                    text: '确定',
                    handler: () => {
                        if (method) {
                            method();
                        }
                        resolve()
                    },
                }
            ],
        });
        await alert.present();
    })
}

global.closeSliding = function (event: any) {
    if (event.target.parentNode.parentNode.close) {
        event.target.parentNode.parentNode.close();
    }
    if (event.target.parentNode.parentNode.parentNode.close) {
        event.target.parentNode.parentNode.parentNode.close();
    }
};

global.audio = function (src: string) {
    const audio = document.createElement("audio");
    audio.src = src;
    document.body.appendChild(audio);
    audio.loop = false;
    try {
        audio.play();
        audio.addEventListener('ended', function () {
            document.body.removeChild(audio);
        }, false);
    } catch (e) {
        console.log("啊哦，播放声音似乎出来问题~~")
    }
};

//内部发音资料
global.audioInnerPath = undefined;
global.setAudioInnerPath = function (val: any) {
    global.audioInnerPath = val;
};

//根据单词获取相对路径
function getRelativePath(word: string) {
    if (word) {
        if (/[\W]/.test(word)) {
            return "other/";
        } else {
            return word.charAt(0).toLowerCase() + "/";
        }
    }
    return "";
};

global.playSound = function (word: string) {
    word = word.trim();
    const encodeWord = encodeURI(word);
    if (global.audioInnerPath) {
        let audioPath = global.audioInnerPath + getRelativePath(word) + encodeWord + ".mp3";
        this.audio(audioPath);
    }
};

global.autoProduceSoundChinese = function (item: any) {
    const param = {
        "msgId": item.vocabularyId + "_" + item.autoIndex,
        "msg": item.word
    }

    myAjax.post("http://47.254.121.216:6039/api/speechMsgForZh", param).then((res) => {
        this.audio("http://47.254.121.216:6039/" + res.data.msg);
    });
};

global.playSoundTemp = function (item: any) {
    let courseId = this.store.getTrialCourseId();
    if (courseId == 2284 || courseId == 2296) {
        //中文发音
        this.autoProduceSoundChinese(item);
        return;
    }

    this.playSound(item.word);
};

router.isReady().then(() => {
    app.mount('#app');
});
